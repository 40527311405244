import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@material-ui/core';
import Send from '@material-ui/icons/Send';
import { RichText } from 'prismic-reactjs';
import React, { useRef } from 'react';
import styled from 'styled-components/macro';

const ButtonIcon = styled(Send)`
  margin-left: ${props => props.theme.spacing(1)}px;
`;

function ProductCard({ product }) {
  const buttonRef = useRef(null);
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Card key={product.url.url}>
      <CardActionArea
        onClick={() => {
          buttonRef.current.click();
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" component="h4">
              <RichText renderAsText={product.producttitle} />
            </Typography>
          }
        />
        <CardMedia component="img" image={product.image.url} alt={product.image.alt} />
        <CardContent>
          <Typography variant="body1">{formatter.format(product.price)} (auf {new URL(product.url.url).hostname})</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          href={product.url.url}
          title={product.url.url}
          target="_blank"
          ref={buttonRef}
        >
          Zum Produkt
          <ButtonIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

export default ProductCard;
