import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import React from 'react';

function Toc({ docs, className }) {
  return (
    <List component="nav" aria-label="Inhaltsverzeichnis" className={className}>
      {docs.map((doc, index) => (
        <React.Fragment key={doc.id}>
          <ListItem button component="a" href={`#${doc.slugs[0]}`}>
            <ListItemText
              primary={<RichText renderAsText={doc.data.title} />}
            />
          </ListItem>
          {index !== docs.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
}

export default Toc;
