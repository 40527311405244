import Prismic from 'prismic-javascript';
import { useEffect, useState } from 'react';

export const client = Prismic.client('https://wish-list.cdn.prismic.io/api/v2');

export const useAllDocs = query => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.query(query, { orderings: '[my.wish.sorting]',  });
      console.log(response);
      if (response) {
        setDocs([response.results, response.results_size]);
      }
    };
    fetchData();
  }, [query]);

  return docs;
};
