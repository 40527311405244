import { CircularProgress, Divider, Typography } from '@material-ui/core';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useAllDocs } from './prismic-config';
import ProductCard from './ProductCard';
import Toc from './Toc';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  ${props => css`
    ${props.theme.breakpoints.up('sm')} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

const ItemDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(5)}px 0;
`;

const ListToc = styled(Toc)`
  margin-bottom: ${props => props.theme.spacing(7)}px;
`;

function List() {
  const [wishes, count] = useAllDocs(Prismic.Predicates.at('document.type', 'wish'));

  return (
    <>
      {wishes ? (
        <>
          <Typography variant="h4" component="h2">
            {count} Wünsche
          </Typography>
          <ListToc docs={wishes} />

          {wishes.map((wish, index) => (
            <React.Fragment key={wish.id}>
              <Typography variant="h5" component="h3" id={wish.slugs[0]}>
                <RichText renderAsText={wish.data.title} />
              </Typography>
              <RichText render={wish.data.description} />

              <Container>
                {wish.data.products.map(product => (
                  <ProductCard product={product} key={product.url.url} />
                ))}
              </Container>

              {index !== wishes.length - 1 && <ItemDivider />}
            </React.Fragment>
          ))}
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export default List;
