import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import NoSsr from '@material-ui/core/NoSsr';
import { createMuiTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { StylesProvider } from '@material-ui/styles';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import 'typeface-roboto';
import List from './List';

const theme = createMuiTheme();

const ListContainer = styled(Container)`
  padding-bottom: ${props => props.theme.spacing(5)}px;
  padding-top: ${props => props.theme.spacing(5)}px;
`;

function App() {
  return (
    <StylesProvider injectFirst>
      <NoSsr>
        <ThemeProvider theme={theme}>
          <>
            <CssBaseline />
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h5" color="inherit" component="h1">
                  Wunschliste
                </Typography>
              </Toolbar>
            </AppBar>
            <ListContainer>
              <List />
            </ListContainer>
          </>
        </ThemeProvider>
      </NoSsr>
    </StylesProvider>
  );
}

export default App;
